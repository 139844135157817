






import { Component, Vue } from 'vue-property-decorator';
import Countdown from "@/components/Countdown.vue";
import RouterNames from "@/router/RouterNames";
import Gallery from '@/components/Gallery.vue';

@Component({
  components: {
    Countdown,
    Gallery,
  },
})
export default class FullGallery extends Vue {}
